import styles from './Services.module.scss';
import Stars from '../stars/Stars';
import { Helmet } from 'react-helmet';

function Services(props: any) {

  return (
    <div className={styles.main + " " + (props.isLight ? styles.Light : styles.Dark)}>
      <Helmet>
        <meta name="description" content="TECHSEED offers solutions for cybersecurity, devops and IT infrastructure, with planning, implementation, coordination and maintenance."></meta>
        <title>Services</title>
      </Helmet>
      <div className={styles.header}>
        services
      </div>

      <div className={styles.section2}>

        <div className={styles.text}>
          <p className={styles.red}>TECHSEED</p> offers <p className={styles.purple}>comprehensive</p>, customer-oriented <p className={styles.blue}>solutions </p> for <p className={styles.red}>cybersecurity</p>, <p className={styles.blue}>devops</p> and IT infrastructure, with planning, implementation, coordination and maintenance.
          Contact us today and see the difference.
        </div>

      </div>
      <Stars isLight={props.isLight}></Stars>

      <div className={styles.portfolio}>
        <p>10+ years</p> of <p>experience</p>
      </div>
    </div >
  );
}

export default Services;