import styles from './Main.module.scss';
import circle1 from '../assets/Images/circle1.png';
import wregra1 from '../assets/Images/wregra1.png';
import techseed from '../assets/Images/techseed.svg';
import arrow from '../assets/Images/arrow.svg';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import "./Tab.scss"
import Stars from '../stars/Stars';
import Slider from 'react-slick';


function Main(props: any) {
  const [value, setValue] = React.useState('1');
  const [show, setShow] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const vendors = [
    {
      img: "dyna.png",
      url: "https://www.dynatrace.com/"
    },
    {
      img: "delphix.png",
      url: "https://www.delphix.com/"
    },
    {
      img: "aws.png",
      url: "https://aws.amazon.com/"
    },
    {
      img: "calico.png",
      url: "https://www.tigera.io/project-calico/"
    },
    {
      img: "cisco.png",
      url: "https://www.cisco.com/"
    },
    {
      img: "codefresh.png",
      url: "https://codefresh.io/"
    },
    {
      img: "crowdstrike.png",
      url: "https://www.crowdstrike.com/"
    },
    {
      img: "dell.png",
      url: "https://www.dell.com/"
    },
    {
      img: "edb.png",
      url: "https://www.enterprisedb.com/"
    },
    {
      img: "fortinet.png",
      url: "https://www.fortinet.com/"
    },
    {
      img: "vmware.png",
      url: "https://www.vmware.com/"
    },
    {
      img: "google.png",
      url: "https://cloud.google.com/"
    },
    {
      img: "hp.png",
      url: "https://www.hp.com/us-en/home.html"
    },
    {
      img: "lenovo.png",
      url: "https://www.lenovo.com/us/en/?Redirect=False"
    },
    {
      img: "logsign.png",
      url: "https://www.logsign.com/"
    },
    {
      img: "netwrix.svg",
      url: "https://www.netwrix.com/"
    },
    {
      img: "okta.png",
      url: "https://www.okta.com/",
    },
    {
      img: "oracle.png",
      url: "https://www.oracle.com/",
    },
    {
      img: "proxmox.png",
      url: "https://www.proxmox.com/",
    },
    {
      img: "solo.io.png",
      url: "https://www.solo.io/",
    },
    {
      img: "wallix.png",
      url: "https://www.wallix.com/",
    },
    {
      img: "armo.png",
      url: "https://www.armosec.io/",
    },
    {
      img: "datree.png",
      url: "https://www.datree.io/",
    },
    {
      img: "haproxy.png",
      url: "https://www.haproxy.com/",
    },
    {
      img: "kasten.png",
      url: "https://www.kasten.io/",
    },
    {
      img: "rancher.png",
      url: "https://www.rancher.com/",
    },
    {
      img: "sonatype.png",
      url: "https://www.sonatype.com/",
    },
    {
      img: "tenable.png",
      url: "https://www.tenable.com/",
    },
    {
      img: "veeam.png",
      url: "https://www.veeam.com/",
    }
  ]

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 400,
    touchMove: false,
    speed: 1000,
    pauseOnHover: false,
    rows: 1,
    responsive: [
      {
        breakpoint: 1610,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          touchMove: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "90px"
        },
      },
      {
        breakpoint: 1075,
        settings: {
          touchMove: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "90px"
        },
      },
      {
        breakpoint: 660,
        settings: {
          arrows: false,
          touchMove: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "90px",
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5px",
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={styles.main + " " + (props.isLight ? styles.Light : styles.Dark)}>
      <div className={styles.header}>
        FUTURE-PROOF YOUR
        <br />
        CYBERSECURITY
        <br />
        FORTIFY YOUR DIGITAL
        <br />
        ECOSYSTEM
      </div>

      <div className={styles.section2}>
        <div className={styles.img}>
          <img src={wregra1} className={styles.image2} alt="" />
          <img src={circle1} className={styles.image1} alt="" />
        </div>

        <div className={styles.text}>
          <p className={styles.red}>TECHSEED</p> offers <p className={styles.purple}>comprehensive</p>, customer-oriented <p className={styles.blue}>solutions </p> for <p className={styles.red}>cybersecurity</p>, <p className={styles.blue}>devops</p> and IT infrastructure, with planning, implementation, coordination and maintenance.
          Contact us today and see the difference.

        </div>

      </div>


      <div className={styles.gradient}>
        <img src={techseed} alt="" />
      </div>

      <div className={styles.wwa}>
        <div className={styles.wwatext}>
          Our team of experts are here to guide you every step of the way.
          <br />
          Let us help you reach the success you deserve.

          <p>
            who we <span className={styles.p}>are</span>
          </p>
        </div>

        <div className={styles.seeMore}>
          <a href="/team">
            <p>
              see more <img src={arrow} alt="" />
            </p>
          </a>
        </div>
      </div>

      <div className={styles.aboutUs}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="about us" value="1" />
                <Tab label="our mission" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1"><p className={styles.content}>At <span className={styles.red}>TECHSEED</span> , we understand the importance of a <span className={styles.blue}>secure</span> and reliable <span className={styles.purple}>digital ecosystem</span>. We routinely review, assess and update our security qualification to ensure our clients are safe from the ever-evolving cyber threats.{show ? " We also design, implement and maintain cybersecurity processes, procedures and technologies to fortify your organization's digital ecosystem. Let us help you secure your digital systems, so that you can focus on your business." : ""}
            </p><button className={styles.show} onClick={() => { setShow(!show) }}>{show ? "show less" : "show more"} <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6.625H12.5M6.875 1L12.5 6.625L6.875 12.25" stroke="#62E7F0" />
            </svg>
              </button></TabPanel>
            <TabPanel value="2"><p className={styles.content}><span className={styles.red}>TECHSEED</span> is committed to providing our clients with best-in-class <span className={styles.purple}>cybersecurity</span> and <span className={styles.blue}>DevOps</span> solutions that empower them to stay ahead of the competition and protect their data from the ever-changing cyber threats.
              {show ? " We are dedicated to helping our clients identify their needs, develop strategies and implement the best practices for their cyber defense. Our team of experienced professionals will guide you every step of the way to ensure your success, optimizing IT infrastructure and network." : ""}
            </p><button className={styles.show} onClick={() => { setShow(!show) }}>{show ? "show less" : "show more"} <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6.625H12.5M6.875 1L12.5 6.625L6.875 12.25" stroke="#62E7F0" />
            </svg>
              </button></TabPanel>
          </TabContext>
        </Box>
      </div>
      <Stars isLight={props.isLight}></Stars>

      <div className={styles.slider}>
        <Slider {...settings}>
          {vendors.map((item, index) => {
            return <div key={index} className={styles.vendor}><a href={item.url} target="_blank" rel="noreferrer"><img alt="" className={styles[item.img.slice(0, -4)]} src={require('../assets/Images/' + item.img)}></img></a></div>
          })}
        </Slider>
      </div>

      <div className={styles.portfolio}>
        <p>10+ years</p> of <p>experience</p>
      </div>
    </div>
  );
}

export default Main;