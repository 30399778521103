import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';
import styles from './About.module.scss';
import src from '../assets/Images/residence.png';
import arrow from '../assets/Images/arrow.svg';
import Team from '../team/Team';
import { Helmet } from 'react-helmet';


function AboutUs(props: any) {
    const [value, setValue] = React.useState('1');
    const [show, setShow] = React.useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div className={styles.main + " " + (props.isLight ? styles.Light : styles.Dark)}>
            <Helmet>
                <meta name="description" content="TECHSEED offers solutions for cybersecurity, devops and IT infrastructure, with planning, implementation, coordination and maintenance."></meta>
                <title>About us</title>
            </Helmet>
            <div className={styles.header}>
                about us
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>
                    <p className={styles.red}>TECHSEED</p> offers <p className={styles.purple}>comprehensive</p>, customer-oriented <p className={styles.blue}>solutions </p> for <p className={styles.red}>cybersecurity</p>, <p className={styles.blue}>devops</p> and IT infrastructure, with planning, implementation, coordination and maintenance.
                    Contact us today and see the difference.
                </div>
            </div>

            <div className={styles.image}>
                <img src={src} alt="" />
            </div>

            <div className={styles.wwa}>
                <div className={styles.wwatext}>
                    Our team of experts are here to guide you every step of the way. Let us help you reach the success you deserve.

                    <div>
                        who we <p>are</p>
                    </div>
                </div>

                <a href="/team">
                    <div className={styles.seeMore}>
                        <p>
                            see more <img src={arrow} alt="" />
                        </p>
                    </div>
                </a>
            </div>

            <div className={styles.aboutUs}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="about us" value="1" />
                                <Tab label="our mission" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1"><p className={styles.content}>At <p className={styles.red}>TECHSEED</p> , we understand the importance of a <p className={styles.blue}>secure</p> and reliable <p className={styles.purple}>digital ecosystem</p>. We routinely review, assess and update our security qualification to ensure our clients are safe from the ever-evolving cyber threats.{show ? " We also design, implement and maintain cybersecurity processes, procedures and technologies to fortify your organization's digital ecosystem. Let us help you secure your digital systems, so that you can focus on your business." : ""}
                        </p><button className={styles.show} onClick={() => { setShow(!show) }}>{show ? "show less" : "show more"} <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 6.625H12.5M6.875 1L12.5 6.625L6.875 12.25" stroke="#62E7F0" />
                        </svg>
                            </button></TabPanel>
                        <TabPanel value="2"><p className={styles.content}><p className={styles.red}>TECHSEED</p> is committed to providing our clients with best-in-class <p className={styles.purple}>cybersecurity</p> and <p className={styles.blue}>DevOps</p> solutions that empower them to stay ahead of the competition and protect their data from the ever-changing cyber threats.
                            {show ? " We are dedicated to helping our clients identify their needs, develop strategies and implement the best practices for their cyber defense. Our team of experienced professionals will guide you every step of the way to ensure your success, optimizing IT infrastructure and network." : ""}
                        </p><button className={styles.show} onClick={() => { setShow(!show) }}>{show ? "show less" : "show more"} <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 6.625H12.5M6.875 1L12.5 6.625L6.875 12.25" stroke="#62E7F0" />
                        </svg>
                            </button></TabPanel>
                    </TabContext>
                </Box>
            </div>

            <div className={styles.team}>
                <Team isLight={props.isLight} />
            </div>
        </div >
    )

}

export default AboutUs;