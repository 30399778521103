import { Dialog } from '@mui/material';
import { useState } from 'react';
import { InlineWidget } from 'react-calendly';
import Slider from 'react-slick';
import styles from './BookStars.module.scss';
import './Slider.scss'

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string) => void;
}

function BookStars(props: any) {

  const [openDevops, setOpenDevops] = useState(false);
  const [openSec, setOpenSec] = useState(false);
  const [openCons, setOpenCons] = useState(false);

  const handleClickOpenSec = () => {
    setOpenSec(true);
  };

  const handleCloseSec = (value: string) => {
    setOpenSec(false);
  };

  const handleClickOpenDevops = () => {
    setOpenDevops(true);
  };

  const handleCloseDevops = (value: string) => {
    setOpenDevops(false);
  };

  const handleClickOpenCons = () => {
    setOpenCons(true);
  };

  const handleCloseCons = (value: string) => {
    setOpenCons(false);
  };

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    rows: 1,
    customPaging: (i: number) => {
      return (
        <div className={styles.dot}>

        </div>)
    },
    responsive: [
      {
        breakpoint: 1610,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          centerMode: true,
          centerPadding: "50px",
          rows: 1,
        },
      },
      {
        breakpoint: 1310,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          centerMode: true,
          centerPadding: "30px",
          rows: 1,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          touchMove: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "100px"
        },
      },
      {
        breakpoint: 1075,
        settings: {
          touchMove: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "200px"
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          autoplay: false,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 660,
        settings: {
          arrows: false,
          touchMove: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5px",
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={styles.stars + " book " + (props.isLight ? styles.Light : styles.Dark)}>

      <p className={styles.header}>what are we doing</p>

      <div className={styles.services}>
        <div className={styles.serviceTitles}>
          <p className={styles.sec}>cybersecurity</p>
          <p className={styles.devops}>devops</p>
          <p className={styles.cons}>infrastructure</p>
        </div>
      </div>

      <div className={styles.rectangles}>
        <div className={styles.rectanglesChild}>
          <Slider {...settings}>
            <div className={styles.secRectangle}>
              <div className={styles.top}>
                <div className={styles.initial}>
                  <p>c</p>
                </div>
                <div className={styles.left}>
                  <p className={styles.serviceLabel}>
                    cybersecurity
                  </p>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                  <p>we specialize in cybersecurity solutions that protect your organization from potential threats. Our team of security experts will work with you to create a needed solution that meets your specific request and keeps your data safe.</p>
                </div>
              </div>
              <div className={styles.book}>
                <button onClick={handleClickOpenSec}>book a meeting</button>
              </div>
            </div>

            <div className={styles.devopsRectangle}>
              <div className={styles.top}>
                <div className={styles.initial}>
                  <p>d</p>
                </div>
                <div className={styles.left}>
                  <p className={styles.serviceLabel}>
                    devops
                  </p>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                  <p>we are experts in DevOps, helping your organization optimize processes, reduce costs, and increase productivity. Our solutions provide a comprehensive set of tools and services that enable you to manage your IT environment.</p>
                </div>
              </div>
              <div className={styles.book}>
                <button onClick={handleClickOpenDevops}>book a meeting</button>
              </div>
            </div>

            <div className={styles.consRectangle}>
              <div className={styles.top}><div className={styles.initial}>
                <p>C</p>
              </div>
                <div className={styles.left}>
                  <p className={styles.serviceLabel}>
                    consulting
                  </p>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                  <p>we can help you build, maintain, and monitor your network with our comprehensive suite of services and products. Our team of experts will ensure that your  IT infrastructure is secure, reliable, and up-to-date with the latest technology.</p>
                </div>
              </div>
              <div className={styles.book}>
                <button onClick={handleClickOpenCons}>book a meeting</button>
              </div>
            </div>

            <div className={styles.devsecopsRectangle}>
              <div className={styles.top}>
                <div className={styles.initial}>
                  <p>d</p>
                </div>
                <div className={styles.left}>
                  <p className={styles.serviceLabel}>
                    devsecops
                  </p>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                  <p>Our DevSecOps solutions align with your organizational needs, ensuring modern, secure workflows. We excel in DevSecOps practices, addressing security, integration, and monitoring. Our proactive methods enhance collaboration, transparency, and performance for your business's operational framework.</p>
                </div>
              </div>
              <div className={styles.book}>
                <button onClick={handleClickOpenDevops}>book a meeting</button>
              </div>
            </div>

            <div className={styles.dbadminRectangle}>
              <div className={styles.top}>
                <div className={styles.initial}>
                  <p>d</p>
                </div>
                <div className={styles.left}>
                  <p className={styles.serviceLabel}>
                    Database
                  </p>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                  <p>With a wealth of experience, our database administrators are devoted to ensuring that your data remains secure, accessible, and performing at its pinnacle. We stay abreast of the latest advancements in database technology, ensuring your systems are modern and capable of meeting the evolving demands of your business.</p>
                </div>
              </div>
              <div className={styles.book}>
                <button onClick={handleClickOpenDevops}>book a meeting</button>
              </div>
            </div>
          </Slider>

        </div>

      </div>
      <div className={styles.portfolio}>
        <p>10+ years</p> of <p>experience</p>
      </div>

      <SecDialogComponent
        open={openSec}
        onClose={handleCloseSec}
      />

      <ConsDialogComponent
        open={openCons}
        onClose={handleCloseCons}
      />

      <DevopsDialogComponent
        open={openDevops}
        onClose={handleCloseDevops}
      />

    </div >
  );
}

function DevopsDialogComponent(props: any) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  var url = "vgrigolaia";
  return (
    <Dialog className={styles.dialog} onClose={handleClose} open={open}>
      <InlineWidget url={`https://calendly.com/${url}?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=393939&text_color=f5f5f5&primary_color=6277F0`}></InlineWidget>
    </Dialog>
  )
}

function SecDialogComponent(props: any) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  var url = "dkatsiashvili_techseed"
  return (
    <Dialog className={styles.dialog} onClose={handleClose} open={open}>
      <InlineWidget url={`https://calendly.com/${url}?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=393939&text_color=f5f5f5&primary_color=6277F0`}></InlineWidget>
    </Dialog>
  )
}

function ConsDialogComponent(props: any) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  var url = "lpkhakadze";
  return (
    <Dialog className={styles.dialog} onClose={handleClose} open={open}>
      <InlineWidget url={`https://calendly.com/${url}?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=393939&text_color=f5f5f5&primary_color=6277F0`}></InlineWidget>
    </Dialog>
  )
}

export default BookStars;