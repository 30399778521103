import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import styles from './Calendar.module.scss';
import './Calendar.scss';
import BookStars from '../book-stars/BookStars';
import { Helmet } from 'react-helmet';


function TalkToUs(props: any) {

    // const { executeRecaptcha } = useGoogleReCaptcha();

    // const [token, changeToken] = useState('');

    // const url = "http://localhost:2000/verify";


    // const handleReCaptchaVerify = useCallback(async () => {
    //     if (!executeRecaptcha) {
    //         return;
    //     }
    //     const token = await executeRecaptcha('yourAction');
    //     console.log(token)
    //     changeToken(token);
    //     const promise = axios.post(url, {
    //         token
    //     });

    //     promise.then(res => {
    //         console.log(res)
    //     })
    // }, [executeRecaptcha]);

    // useEffect(() => {
    //     handleReCaptchaVerify();
    // }, [handleReCaptchaVerify]);


    return (
        <div className={styles.main + " " + (props.isLight ? styles.Light : styles.Dark)}>
            <Helmet>
                <meta name="description" content="Schedule a call with us."></meta>
                <title>Talk to us</title>
            </Helmet>

            <div className={styles.header}>
                Talk to us
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>
                    <p className={styles.red}>TECHSEED</p> offers <p className={styles.purple}>comprehensive</p>, customer-oriented <p className={styles.blue}>solutions </p> for <p className={styles.red}>cybersecurity</p>, <p className={styles.blue}>devops</p> and IT infrastructure, with planning, implementation, coordination and maintenance.
                    Contact us today and see the difference.
                </div>
            </div>


            <div>
                <div className={styles.calendar}>
                    <BookStars isLight={props.isLight}></BookStars>
                </div>
            </div>
        </div>)
}

export default TalkToUs;