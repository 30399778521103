import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey="6LficIAjAAAAAJXAIEwAGlX0SYSUBiEyerujU4Vs">
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);