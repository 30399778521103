import styles from './ThemeSwitch.module.scss';

type ThemeSwitchPropsType = {
  isLight: boolean;
  handleClick: () => void;
};

export const ThemeSwitch: React.FC<ThemeSwitchPropsType> = ({isLight = false, handleClick}) => {
  return (

    <button className={styles.button} onClick={handleClick}>
      <div className={styles.circle1 + " " + (!isLight ? styles.dark1 : "")}>

      </div>
      <div className={styles.circle2 + " " + (!isLight ? styles.dark2 : "")}>

      </div>
    </button>
  );
}