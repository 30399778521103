import { useState } from 'react';
import styles from './Work.module.scss';
import arrow from '../assets/Images/arrow.svg';
import { Helmet } from 'react-helmet';

const devopsProjects: any[] = [
    {
        title: "Kubernetes Storage Solutions Deployment",
        tags: ["devops"],
        description: "Deployed Longhorn, Ceph- Rook, and vSphere Kubernetes Storages for efficient and reliable data storage solutions in Kubernetes clusters, resulting in increased performance and scalability of applications."
    },
    {
        title: "Network Security Implementation with Calico Enterprise",
        tags: ["devops"],
        description: "Installed Calico Enterprise, a comprehensive network security platform, to protect against threats and enforce network policies."
    },
    {
        title: "Container Security Platform Development",
        tags: ["devops"],
        description: "Developed a Full Lifecycle Container Security Platform to ensure secure and compliant container environments, allowing for streamlined management and deployment of containers at scale."
    },
    {
        title: "CICD Process Establishment for Agile Software Delivery",
        tags: ["devops"],
        description: "Implemented Continuous Integration and Continuous Delivery (CICD) Processes to enable faster and more frequent delivery of software updates with greater reliability and automation."
    },
    {
        "title": "Deployment of Zero Trust Container Security Platform",
        tags: ["devops"],
        "description": "Deployed Zero Trust Container Security Platform to ensure secure container environments, providing enhanced protection against threats and vulnerabilities."
    },
    {
        "title": "Object Storage Deployment",
        tags: ["devops"],
        "description": "Deployed Object Storages for efficient and reliable storage solutions, allowing for seamless management and scalability of data."
    },
    {
        "title": "Full-Stack APM and ITOA Deployment",
        tags: ["devops"],
        "description": "Deployed full-stack application performance management and IT operations analytics solutions to monitor and analyze the performance and health of applications and infrastructure."
    },
    {
        "title": "Infrastructure Monitoring Solutions Deployment",
        tags: ["devops"],
        "description": "Deployed Infrastructure Monitoring Solutions to monitor the health and performance of infrastructure components, providing insights into system availability and uptime."
    },
    {
        "title": "Service Meshes Istio/Gloo Deployment",
        tags: ["devops"],
        "description": "Deployed Service Meshes Istio/Gloo for seamless communication and management of microservices, providing enhanced security and reliability."
    },
    {
        "title": "HCI Infrastructure and Virtualization Platforms Deployment",
        tags: ["devops"],
        "description": "Deployed HCI infrastructure and Virtualization Platforms KVM/Vmware to optimize and streamline virtualized environments, increasing flexibility and efficiency."
    }
]
const infraProjects: any[] = [
    {
        "title": "Firewall Implementation and Management",
        "description": "Installed and managed firewall solutions to protect against cyber threats, ensuring the confidentiality and integrity of sensitive data and resources.",
        "tags": ["infrastructure"]
    },

    {
        "title": "Network Design and Implementation",
        "description": "Designed and implemented a scalable and reliable network architecture to meet the organization's business needs and improve communication and collaboration.",
        "tags": ["infrastructure"]
    },

    {
        "title": "Network Monitoring and Management",
        "description": "Monitored network performance and conducted proactive maintenance activities to ensure consistent and reliable network connectivity, minimizing downtime and disruption.",
        "tags": ["infrastructure"]
    },

    {
        "title": "Network Access Control Implementation",
        "description": "Implemented network access control solutions to enforce security policies and restrict unauthorized access to network resources, protecting against potential cyber threats and data breaches.",
        "tags": ["infrastructure"]
    },

    {
        "title": "Server Deployment and Management",
        "description": "Deployed and managed servers to support the organization's business needs, ensuring optimal performance, reliability, and security.",
        "tags": ["infrastructure"]
    },

    {
        "title": "Virtualization Implementation and Management",
        "description": "Implemented and managed virtualization solutions to improve server utilization, reduce costs, and increase agility and flexibility.",
        "tags": ["infrastructure"]
    },

    {
        "title": "Storage Solutions Deployment and Management",
        "description": "Deployed and managed storage solutions to ensure the availability, integrity, and confidentiality of critical data, enhancing business continuity and disaster recovery capabilities.",
        "tags": ["infrastructure"]
    },

    {
        "title": "Cloud Migration and Management",
        "description": "Migrated on-premises infrastructure to cloud-based solutions, ensuring scalability, cost-effectiveness, and accessibility of resources, while maintaining security and compliance requirements.",
        "tags": ["infrastructure"]
    }
]
const secProjects = [
    {
        title: "Endpoint Protection/EDR Implementation and support",
        tags: ["security"],
        description: "PROTECTING WORKSTATIONS AND SERVERS, DETECTING/RESPONDING TO THREATS USING CUTTING-EDGE TECHNOLOGY"
    },
    {
        title: "Sandbox deployment and health checks",
        tags: ["security"],
        description: "SANDBOX DETECTS AND PREVENTS ADVANCED AND ZERO-DAY THREATS"
    },
    {
        title: "Privileged Access Management implementation",
        tags: ["security"],
        description: "PRIVILEGED ACCESS MANAGEMENT SECURING ACCESS TO SENSITIVE SERVERS BY CONTROLLING ANDMONITORING PRIVILEGED USER ACCESS"
    },
    {
        title: "Data Loss Prevention Deployment",
        tags: ["security"],
        description: "DATA LOSS PREVENTION (DLP) PROTECTING DATA FROM BEING LEAKED, STOLEN OR LOST USING SOFTWARE AND POLICIES THAT IDENTIFY AND PREVENT UNAUTHORIZED USAGE"
    },
    {
        title: "Web Application Firewall Deployment and Support",
        tags: ["security"],
        description: "WEB APPLICATION FIREWALL (WAF) SECURITY SOLUTION THAT PROTECTS WEB APPLICATIONS FROM CYBER THREATS"
    },
    {
        title: "Secure Email Gateway Deployment",
        tags: ["security"],
        description: "SECURE EMAIL GATEWAY IS A SECURITY SOLUTION THAT SCANS EMAILS TO PREVENT UNAUTHORIZED ACCESS, BLOCK SPAM, AND PROTECT AGAINST EMAIL-BASED CYBER THREATS"
    },
    {
        title: "Vulnerability Management Implementation",
        tags: ["security"],
        description: "VULNERABILITY MANAGEMENT INVOLVES IDENTIFYING, ASSESSING, PRIORITIZING, AND MITIGATING VULNERABILITIES IN AN ORGANIZATION'S IT SYSTEMS AND NETWORK TO IMPROVE SECURITY POSTURE"
    },
    {
        title: "SIEM Deployment/Support",
        tags: ["security"],
        description: "SIEM IS A SECURITY SOLUTION THAT COLLECTS, CORRELATES, ANALYZES AND REPORTS LOG DATA TO DETECT AND RESPOND TO SECURITY THREATS"
    },
]

var devopsPages: Array<any> = [];
var secPages: Array<any> = [];
var infraPages: Array<any> = [];

devopsPages = setPages(devopsProjects);
infraPages = setPages(infraProjects);
secPages = setPages(secProjects);

function setPages(list: Array<any>) {
    var result = [];
    for (var i = 1; i <= (list.length % 3 > 0 ? Math.floor(list.length / 3) + 1 : list.length / 3); i++) {
        result.push(i);
    }

    return result;
}

function Work(props: any) {

    const [selected, setSelected] = useState(0);
    const [openDevops, setOpenDevOps] = useState(false);
    const [openSec, setOpenSec] = useState(false);
    const [openInfra, setopenInfra] = useState(false);
    const [pageDevops, setPageDevops] = useState(1);
    const [pageInfra, setPageInfra] = useState(1);
    const [pageSec, setPageSec] = useState(1);

    function getPages(pages: Array<any>, page: any) {
        if (pages.length < 7) {
            return {
                left: false,
                right: false,
                pages: [...pages]
            }
        }
        if (page >= pages[0] + 3 && page + 3 <= pages[pages.length - 1]) {
            return {
                left: true,
                right: true,
                pages: [page - 1, page, page + 1]
            }

        } else if (page <= pages[0] + 3 && page + 3 <= pages[pages.length - 1]) {
            return {
                left: false,
                right: true,
                pages: pages.slice(0, 4)
            }
        } else if (page >= pages[0] + 3 && page + 3 >= pages[pages.length - 1]) {
            return {
                left: true,
                right: false,
                pages: [pages[pages.length - 4], pages[pages.length - 3], pages[pages.length - 2], pages[pages.length - 1]]
            }
        }
        return {
            left: false,
            right: false,
            pages: [...pages]
        }
    }

    let paginationDevops = getPages(devopsPages, pageDevops);
    let paginationInfra = getPages(infraPages, pageInfra);
    let paginationSec = getPages(secPages, pageSec);

    return (
        <div className={styles.main + " " + (props.isLight ? styles.Light : styles.Dark)}>
            <Helmet>
                <meta name="description" content="Find out more about our services."></meta>
                <title>Our Work</title>
            </Helmet>

            <div className={styles.header}>
                our <p>work</p>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.text}>
                    <p className={styles.red}>Protect</p> your <p className={styles.purple}>business</p> and stay <p className={styles.blue}>ahead</p> of the competition with <p className={styles.red}>Techseed</p>'s advanced <p className={styles.blue}>cybersecurity</p> and DevOps solutions. Check out our portfolio to see how we've helped other organizations succeed.
                </div>
            </div>
            <div className={styles.rectangles}>
                <div className={styles.row}>
                    <div className={`${styles.security} ${styles.slide}`} onClick={() => { setOpenSec(!openSec) }}>
                        <div className={styles.initial}>
                            <p>C</p>
                        </div>
                        <div className={styles.left}>
                            <p className={styles.serviceLabel}>
                                cybersecurity
                            </p>
                            <p className={styles.serviceText}>
                                Secure your Environment with best in class solutions
                            </p>
                            <p className={styles.clickToOpen}>
                                <button className={styles.btn} onClick={() => { setOpenSec(!openSec) }}><p className={styles.p1}>{openSec ? "close" : "open"}</p> <div className={styles.img}><img style={{ transform: openSec ? "rotate(270deg)" : "rotate(90deg)" }} src={arrow} alt="" /></div></button>
                            </p>
                        </div>
                    </div>
                    <div className={styles.open}>
                        <p className={styles.projects}>{secProjects.length} projects</p>
                        <button className={styles.btn} onClick={() => { setOpenSec(!openSec) }}><p className={styles.p1}>{openSec ? "close" : "open"}</p> <div className={styles.img}><p>{openSec ? "close" : "open"}</p><img style={{ transform: openSec ? "rotate(270deg)" : "rotate(90deg)" }} src={arrow} alt="" /></div></button>
                    </div>
                </div>
                <div className={styles.list + " " + (openSec ? '' : styles.closed)}>
                    {getList(secProjects, pageSec).map((item: any, index: any) => {
                        return <div key={index} className={styles.project}>
                            <div className={styles.id}>{((pageSec - 1) * 3) + index + 1}</div>
                            <div>
                                <div className={styles.details}>
                                    <div className={styles.title}>
                                        {item.title}
                                    </div>

                                    <div className={styles.tags}>
                                        {item.tags.map((tag: any, index: any) => {
                                            return <div key={index} className={styles.tag + " " + styles[tag + "_tag"]}>{tag}</div>
                                        })}
                                    </div>

                                    <div className={styles.description}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    <div className={styles.pagination}>
                        <button className={styles.arrows} onClick={() => {
                            if (pageSec > 1) {
                                setPageSec(pageSec - 1)
                            }
                        }} disabled={pageSec <= 1}><img style={{ transform: "rotate(180deg)" }} src={arrow} alt="" /></button>
                        {paginationSec.left ? (<div className={styles.first} onClick={() => { setPageSec(1) }}>1</div>) : (<div></div>)}
                        {paginationSec.left ? (<div className={styles.dots}>.....</div>) : (<div></div>)}
                        {paginationSec.pages.map((item: any, index: any) => {
                            return (<div className={(item === pageSec ? styles.selected : styles.page)} key={index} onClick={() => { setPageSec(item) }}>{item}</div>)
                        })}
                        {paginationSec.right ? (<div className={styles.dots}>.....</div>) : (<div></div>)}
                        {paginationSec.right ? (<div className={styles.last} onClick={() => { setPageSec(secPages[secPages.length - 1]) }}>{secPages[secPages.length - 1]}</div>) : (<div></div>)}
                        <button className={styles.arrows} onClick={() => {
                            if (pageSec < secPages.length) {
                                setPageSec(pageSec + 1)
                            }
                        }} disabled={pageSec >= secPages.length}><img src={arrow} alt="" /></button>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.devops} ${styles.slide}`} onClick={() => { setOpenDevOps(!openDevops) }}>
                        <div className={styles.initial}>
                            <p>D</p>
                        </div>
                        <div className={styles.left}>
                            <p className={styles.serviceLabel}>
                                devops
                            </p>
                            <p className={styles.serviceText}>
                                Secure your Environment with best in class solutions
                            </p>
                            <p className={styles.clickToOpen}>
                                <button className={styles.btn} onClick={() => { setOpenDevOps(!openDevops) }}><p className={styles.p1}>{openDevops ? "close" : "open"}</p> <div className={styles.img}><img style={{ transform: openDevops ? "rotate(270deg)" : "rotate(90deg)" }} src={arrow} alt="" /></div></button>
                            </p>
                        </div>
                    </div>
                    <div className={styles.open}>
                        <p className={styles.projects}>{devopsProjects.length} projects</p>
                        <button className={styles.btn} onClick={() => { setOpenDevOps(!openDevops) }}><p className={styles.p1}>{openDevops ? "close" : "open"}</p> <div className={styles.img}><p>{openDevops ? "close" : "open"}</p><img style={{ transform: openDevops ? "rotate(270deg)" : "rotate(90deg)" }} src={arrow} alt="" /></div></button>
                    </div>
                </div>
                <div className={styles.list + " " + (openDevops ? '' : styles.closed)}>
                    {getList(devopsProjects, pageDevops).map((item: any, index: any) => {
                        return <div key={index} className={styles.project}>
                            <div className={styles.id}>{((pageDevops - 1) * 3) + index + 1}</div>
                            <div>
                                <div className={styles.details}>
                                    <div className={styles.title}>
                                        {item.title}
                                    </div>

                                    <div className={styles.tags}>
                                        {item.tags.map((tag: any, index: any) => {
                                            return <div key={index} className={styles.tag + " " + styles[tag + "_tag"]}>{tag}</div>
                                        })}
                                    </div>

                                    <div className={styles.description}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    <div className={styles.pagination}>
                        <button className={styles.arrows} onClick={() => {
                            if (pageDevops > 1) {
                                setPageDevops(pageDevops - 1)
                            }
                        }} disabled={pageDevops <= 1}><img style={{ transform: "rotate(180deg)" }} src={arrow} alt="" /></button>
                        {paginationDevops.left ? (<div className={styles.first} onClick={() => { setPageDevops(1) }}>1</div>) : (<div></div>)}
                        {paginationDevops.left ? (<div className={styles.dots}>.....</div>) : (<div></div>)}
                        {paginationDevops.pages.map((item: any, index: any) => {
                            return (<div className={(item === pageDevops ? styles.selected : styles.page)} key={index} onClick={() => { setPageDevops(item) }}>{item}</div>)
                        })}
                        {paginationDevops.right ? (<div className={styles.dots}>.....</div>) : (<div></div>)}
                        {paginationDevops.right ? (<div className={styles.last} onClick={() => { setPageDevops(devopsPages[devopsPages.length - 1]) }}>{devopsPages[devopsPages.length - 1]}</div>) : (<div></div>)}
                        <button className={styles.arrows} onClick={() => {
                            if (pageDevops < devopsPages.length) {
                                setPageDevops(pageDevops + 1)
                            }
                        }} disabled={pageDevops >= devopsPages.length}><img src={arrow} alt="" /></button>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.infra} ${styles.slide}`} onClick={() => { setopenInfra(!openInfra) }}>
                        <div className={styles.initial}>
                            <p>I</p>
                        </div>
                        <div className={styles.left}>
                            <p className={styles.serviceLabel}>
                                infrastructure
                            </p>
                            <p className={styles.serviceText}>
                                Secure your Environment with best in class solutions
                            </p>
                            <p className={styles.clickToOpen}>
                                <button className={styles.btn} onClick={() => { setopenInfra(!openInfra) }}><p className={styles.p1}>{openInfra ? "close" : "open"}</p> <div className={styles.img}><img style={{ transform: openInfra ? "rotate(270deg)" : "rotate(90deg)" }} src={arrow} alt="" /></div></button>
                            </p>
                        </div>
                    </div>
                    <div className={styles.open}>
                        <p className={styles.projects}>{infraProjects.length} projects</p>
                        <button className={styles.btn} onClick={() => { setopenInfra(!openInfra) }}><p className={styles.p1}>{openInfra ? "close" : "open"}</p> <div className={styles.img}><p>{openInfra ? "close" : "open"}</p><img style={{ transform: openInfra ? "rotate(270deg)" : "rotate(90deg)" }} src={arrow} alt="" /></div></button>
                    </div>
                </div>
                <div className={styles.list + " " + (openInfra ? '' : styles.closed)}>
                    {getList(infraProjects, pageInfra).map((item: any, index: any) => {
                        return <div key={index} className={styles.project}>
                            <div className={styles.id}>{((pageInfra - 1) * 3) + index + 1}</div>
                            <div>
                                <div className={styles.details}>
                                    <div className={styles.title}>
                                        {item.title}
                                    </div>

                                    <div className={styles.tags}>
                                        {item.tags.map((tag: any, index: any) => {
                                            return <div key={index} className={styles.tag + " " + styles[tag + "_tag"]}>{tag}</div>
                                        })}
                                    </div>

                                    <div className={styles.description}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    <div className={styles.pagination}>
                        <button className={styles.arrows} onClick={() => {
                            if (pageInfra > 1) {
                                setPageInfra(pageInfra - 1)
                            }
                        }} disabled={pageInfra <= 1}><img style={{ transform: "rotate(180deg)" }} src={arrow} alt="" /></button>
                        {paginationInfra.left ? (<div className={styles.first} onClick={() => { setPageInfra(1) }}>1</div>) : (<div></div>)}
                        {paginationInfra.left ? (<div className={styles.dots}>.....</div>) : (<div></div>)}
                        {paginationInfra.pages.map((item: any, index: any) => {
                            return (<div className={(item === pageInfra ? styles.selected : styles.page)} key={index} onClick={() => { setPageInfra(item) }}>{item}</div>)
                        })}
                        {paginationInfra.right ? (<div className={styles.dots}>.....</div>) : (<div></div>)}
                        {paginationInfra.right ? (<div className={styles.last} onClick={() => { setPageInfra(infraPages[infraPages.length - 1]) }}>{infraPages[infraPages.length - 1]}</div>) : (<div></div>)}
                        <button className={styles.arrows} onClick={() => {
                            if (pageInfra < infraPages.length) {
                                setPageInfra(pageInfra + 1)
                            }
                        }} disabled={pageInfra >= infraPages.length}><img src={arrow} alt="" /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getList(list: any, page: number) {
    return list.slice((page - 1) * 3, (page - 1) * 3 + 3);
}

export default Work;