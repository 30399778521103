import { ReactNode } from 'react';
import styles from './Paper.module.scss';

type PaperPropsType = {
  isLight: boolean;
  element: ReactNode;
};

export const Paper: React.FC<PaperPropsType> = ({ isLight = true, element }) => {
  return (
    <div className={styles.Paper + " " +(isLight ? styles.LightPaper : styles.DarkPaper)}>
      {element}
    </div>
  );
}

export default Paper;